



































import Vue from "vue";


var _self = this;
export default Vue.extend({
  name: "MainContent",
  components: {
    MaterialCard: () => import("./MaterialCard.component.vue"),
    RadarChart: () => import("./RadarChart.component.vue"),
    Viewer3D: () => import("./Viewer3D.component.vue"),
    OptionSummary: () => import("./OptionSummary.component.vue"),
  },
    data() {
        return {
            radarTooltipText: '',
            radarTooltip: false,
            tooltipPositionX: 0,
            tooltipPositionY: 0,
      tab: null,
      loading: true,     
    };
  },
  async created() {
    this.loading = false;
  },
  async mounted() {
    this.$root.$on('onRadarTooltip', this.onRadarTooltip);
    this.startTour();
  },
  async beforeDestroy() {},
  methods: {
        startTour() {
            var ttour = localStorage.getItem('timbertour');
            if (ttour && ttour == 'false') { return; }
            this.$tours["timberTour"].start();
        },
        onRadarTooltip(params) {
            if (!params) {
                this.radarTooltip = false;
                return;
            }
            this.radarTooltipText = params.text;
            this.tooltipPositionX = params.x;
            this.tooltipPositionY = params.y;
            this.radarTooltip = true;                                            
        },    
  },
});
